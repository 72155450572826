import {CryptoSliderCard,Wrapper} from "../../components";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import {useQuery} from "@tanstack/react-query";
import {getMarket,getTickers,getCurrencies} from "../../utils/GetData";

function CryptoSlider() {

    const { data: marketData, isLoading: marketDataLoading, error: marketDataError } = useQuery({
        queryKey: 'markets',
        queryFn: getMarket,
    });

    const { data: tickersData, isLoading: tickersDataLoading, error: tickersDataError } = useQuery({
        queryKey: 'tickers',
        queryFn: getTickers,
    });

    const { data: currenciesData, isLoading: currenciesDataLoading, error: currenciesDataError } = useQuery({
        queryKey: 'currencies',
        queryFn: getCurrencies,
    });

    if(marketDataLoading || tickersDataLoading || currenciesDataLoading){
        return <div className="text-surface-main">Loading...</div>
    }

    if(marketDataError || tickersDataError || currenciesDataError){
        return <div>Error...</div>
    }

    const combinedData = marketData.map((item) => {
        return {
            ...item,
            ticker: tickersData[item.id]
        }
    })

    const renderSliderCard = (market) => {
        const icon = currenciesData.find((item) => item.id === market.base_unit).icon_url || ""

        return(
            <CryptoSliderCard
                data={market}
                icon={icon}
            />
        )
    }



    return(
        <Wrapper>
            <div className="rounded-3xl border border-dark-line overflow-hidden bg-dark-backgroundOnDark backdrop-blur-sm px-8 py-12 -mt-16 flex justify-between">
                <Swiper

                    breakpoints={{

                        640: {
                            center: true,
                            slidesPerView: 1,
                        },

                        768: {
                            slidesPerView: 3.5,
                        },
                    }}
                    loop={true}
                    autoplay={
                        {
                            delay: 500,
                        }
                    }
                >
                    {
                        combinedData.map((item,index) => (
                            <SwiperSlide key={index}>
                                {renderSliderCard(item)}
                            </SwiperSlide>

                        ))
                    }
                </Swiper>

            </div>
        </Wrapper>
    )
}

export {CryptoSlider}
