import {Wrapper} from "../../components";

function PrivacyPolicy () {
    return (
        <Wrapper className="min-h-screen">
            <h3 className="text-white text-5xl text-center font-semibold my-4">Gizlilik Sözleşmesi</h3>
            <div className="mt-16">
                <h2 className="text-white text-3xl font-semibold my-4">Sözleşmeye Giriş</h2>
                <p className="text-white mt-4">
                    ("Gms Global Teknoloji Anonim Şirketi veya Şirketimiz ") olarak, web servis ve dijital
                    kaynaklarımızın herhangi birini kullanan müşterilerimizin veya üçüncü kişilerin ("Kullanıcılar")
                    kişisel verilerinin korunması, bu hususlarda şeffaflık ve karşılıklı güven oluşturmak
                    önceliklerimizdendir. Web adresimiz https://gmskripto.com.com/ uzantıları ve bunlarla birlikte ama
                    bunlarla sınırlı olmamak üzere sosyal medya sayfalarımızı ve/veya dijital kanallarımızı ziyaret
                    etmeniz, hizmet ve ürünlerimizi kullandığınız veya destek hizmetlerimizden yararlandığınız durumlar
                    gibi GmsKripto online ve dijital kanallarımız üzerinden ziyaret ettiğiniz anlarda otomatik olan veya
                    olmayan yöntemlerle kaydettiğimiz ve elektronik olarak bizimle paylaştığınız kişisel verileriniz,
                    öncelikle taleplerinizin yerine getirilmesini temin edebilmek, daha sonra ise size daha iyi hizmet
                    sunmak amacıyla kullanılacaktır
                    Kişiler verilerinizin Gms Kripto tarafından işlenmesi ile ilgili daha detaylı bilgiye Kişisel
                    Verilerinin Korunması'ndan ve Çerez Politikası'ndan ulaşabilirsiniz.

                    Onaylı veri tabanımızda yer almanız halinde ise, Şirketimiz tarafından sunulan ürün ve hizmetlerle
                    ilgili olarak tanıtım, pazarlama ve promosyon faaliyetleri yapılması için ileti gönderilebilecektir.
                    Şirketimiz tarafından gönderilen iletileri almak istemiyorsanız, size sunulan reddetme hakkını
                    kullanarak örneğin; "GmsKripto'ten yeni ürün ve hizmet tanıtımına ilişkin herhangi bir e-posta almak
                    istemiyorsanız https://gmskripto.com/giriş yaptıktan sonra ayarlar kısmından iletişim tercihlerinizi
                    değiştirebilirsiniz." bağlantısına tıklayarak, ilgili kanala ait listemizden çıkabilirsiniz.
                </p>
            </div>
            <div className="mt-16">
                <h2 className="text-white text-3xl font-semibold my-4">Sorumluluğun Sınırlandırılması</h2>
                <p className="text-white mt-4">
                    Bu internet sitesinde verilen bilgiler, "olduğu gibi", "mevcut olduğu şekilde" sağlanmaktadırlar. Gms Kripto, bu bilgilerin doğruluklarını, yeterliliklerini ve eksiksizliklerini garanti etmemekte ve bu bilgilerdeki hatalar ya da eksiklikler nedeniyle sorumluluğu, açık bir şekilde reddetmektedir. Bu bilgilerle üçüncü şahısların haklarının ihlal edilmemesi; mülkiyet, belirli bir amaç için uygunluk ve/veya bilgisayar virüsü bulunmamasına ilişkin garantiler dahil ancak bunlarla sınırlı kalmamak kaydıyla, zımnen, açıkça ya da yasal olarak hiçbir garanti vermemektedir.


                    Bu internet sitesinin kullanımı sırasında herhangi bir performans arızası, hata, eksiklik, kesinti kusur, işletme ve/veya nakilde gecikme, bilgisayar virüsü ve/veya hat ve/veya sistem arızası sonucu ortaya çıkan doğrudan ya da dolaylı zarar, ziyan ve masraflar da dahil ancak bunlarla sınırlı olmamak üzere hiçbir zarar ve ziyandan, Gms Kripto ve/veya çalışanları, bu tür bir zarar ve ziyan olasılığından haberdar edilmiş olsalar dahi, sorumlu olmazlar.


                    Gerek bu internet sitesinden bağlantı verilen sitelerdeki, gerekse de diğer internet sitelerine bağlantıların riski, kullanıcıya aittir. Bu sitelerde verilen bilgilerin doğruluğu ile bu sitelerden verilen diğer bağlantıların nitelikleri Gms Kripto tarafından araştırılmamış ve doğrulanmamıştır.


                    Gms Kripto, bu internet sitesinde yer alan bütün ürün ve hizmetleri, sayfaları, bilgileri, görsel unsurları önceden bildirimde bulunmadan değiştirme hakkını saklı tutar
                </p>
            </div>
        </Wrapper>
    );
}

export {PrivacyPolicy};
