import {About, CryptoSlider, DownloadApp, Footer, Hero, HowItWork, MarketList, Navbar} from "../../containers";

function MainScreen () {
    return (
      <>

          <Hero/>
          <CryptoSlider/>
          <MarketList/>
          <HowItWork/>
          <About/>
          <DownloadApp/>

      </>
    );
}

export { MainScreen };
