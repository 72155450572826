import {Wrapper} from "../../components";
import {TiTick} from "react-icons/ti";
import AppStore from "../../assets/AppStore.png";
import PlayStore from "../../assets/PlayStore.png";
import DownloadAppImg from "../../assets/downloadApp.png"

function DownloadApp() {
    return(
        <div className="bg-dark-backgroundOnDark pt-20">
            <Wrapper>
                <div className="grid grid-cols-12 items-center">
                    <div className="md:col-start-1 md:col-end-7 col-start-1 col-end-13">
                        <h2 className="text-surface-main font-bold text-5xl">Mobil Uygulamalar İle <br/> Anında İşlem Yap!</h2>
                        <p className="text-secondaryTwo mt-3">GMS Kripto ile ticaret becerilerinizin en iyi şekilde değerlendirildiğinden emin olabilirsiniz.</p>
                        <div className="mt-8">
                            <div>
                                <div className="flex items-center gap-3">
                                    <div className="bg-primary w-5 h-5 rounded-full flex items-center justify-center">
                                        <TiTick/>
                                    </div>
                                    <h2 className="text-surface-main font-bold text-xl font-dmsans">7/24 Alım/Satım
                                        Yapın!</h2>
                                </div>
                                <div className="mt-2">
                                    <p className="text-secondaryTwo ">Kripto varlıklarınızı mobil cihazınızdan
                                        yönetin.</p>
                                </div>
                            </div>
                            <div className="mt-4">
                                <div className="flex items-center gap-3">
                                    <div className="bg-primary w-5 h-5 rounded-full flex items-center justify-center">
                                        <TiTick/>
                                    </div>
                                    <h2 className="text-surface-main font-bold text-xl font-dmsans">Varlıklarınızın Kontrolünü Ele Alın!</h2>
                                </div>
                                <div className="mt-2">
                                    <p className="text-secondaryTwo ">Sadece sizin erişebileceğiniz fonlarınıza güvenle sahip çıkın.</p>
                                </div>
                            </div>
                            <div className="flex items-center gap-x-4 mt-8">
                                <img src={AppStore} alt=""/>
                                <img src={PlayStore} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="md:col-start-8 md:col-end-13 col-start-1 col-end-13 hidden md:block">
                        <img src={DownloadAppImg} alt=""/>
                    </div>
                </div>
            </Wrapper>
        </div>
    )
}

export {DownloadApp};
