import {Wrapper,Button} from "../../components";
import AboutImg from "../../assets/aboutImg.png";
import { TiTick } from "react-icons/ti";



function About(){
    return(
        <Wrapper>
            <div className="pb-24 pt-16 grid grid-cols-12">
                <div className="md:col-start-1 md:col-end-7 col-start-1 col-end-13 hidden md:block">
                    <img className="w-[712px] block" src={AboutImg} alt=""/>
                </div>
                <div className="md:col-start-8 md:col-end-13 col-start-1 col-end-13">
                    <div>
                        <h2 className="font-dmsans font-bold text-5xl text-surface-main">Artık Çok Kolay!</h2>
                        <p className="font-dmsans text-xl text-secondaryTwo mt-3">GMS Kripto, çeşitli kripto para
                            işlemlerini kolay ve güvenli bir şekilde yapmanızı sağlar.</p>
                    </div>
                    <div className="mt-14">
                        <div>
                            <div className="flex items-center gap-3">
                                <div className="bg-primary w-5 h-5 rounded-full flex items-center justify-center">
                                    <TiTick/>
                                </div>
                                <h2 className="text-surface-main font-bold text-xl font-dmsans">Gerçek Zamanlı Kripto
                                    Para Fiyatlarını Görüntüleyin</h2>
                            </div>
                            <div className="mt-2">
                                <p className="text-secondaryTwo ">GMS Kripto üzerinde anlık piyasa verilerini takip edin
                                    ve doğru zamanda doğru yatırım kararları verin.</p>
                            </div>
                        </div>
                        <div className="mt-11">
                            <div className="flex items-center gap-3">
                                <div className="bg-primary w-5 h-5 rounded-full flex items-center justify-center">
                                    <TiTick/>
                                </div>
                                <h2 className="text-surface-main font-bold text-xl font-dmsans">BTC, ETH, XRP ve Daha Fazlasını Alıp Satın</h2>
                            </div>
                            <div className="mt-2">
                                <p className="text-secondaryTwo ">GMS Kripto ile çeşitli kripto paraları kolayca alıp satabilir, geniş işlem yelpazesi ile yatırım yapabilirsiniz.</p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-10">
                        <Button onClick={() => window.location.href = `https://exchange.gmskripto.com/signin`}>Hemen Üye Ol</Button>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export {About}
