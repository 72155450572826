import {Wrapper,Button} from "../../components";
import HeroImg from "../../assets/hero2.png";

function Hero(){
    return(
       <div className="bg-[#18191D]">
           <Wrapper>
               <div className="grid grid-cols-12 items-center pt-36 py-52 bg-[#18191D]">
                   <div className="md:col-start-1 md:col-end-6 col-start-1 col-end-13">
                       <h3 className="font-dmsans font-bold text-6xl text-surface-main">Güvenilir ve Hızlı <br/> Kripto Para Al/Sat!</h3>
                       <p className="text-secondaryTwo mt-6 mb-12">Hızlı ve güvenilir bir şekilde Kripto Para almak ve satmak <span className="text-primary">çok kolay!</span> <br/> Hemen üye olun ve kripto dünyasına adım atın.</p>
                       <Button onClick={() => window.location.href = `https://exchange.gmskripto.com`}>Hemen Başla</Button>
                   </div>
                   <div className="md:col-start-8 md:col-end-13 col-start-1 col-end-13 hidden md:block mt-20 md:mt-0">
                       <img className="max-w-[570px] w-full" src={HeroImg} alt="Hero Img"/>
                   </div>
               </div>
           </Wrapper>
       </div>
    )
}

export {Hero}
