export const NAVBAR = [
    {
        "link":"#",
        "name":"Anasayfa"
    },
    {
        "link":"https://exchange.gmskripto.com/",
        "name":"Piyasalar"
    },
    {
        "link":"https://exchange.gmskripto.com/protade",
        "name":"Al/Sat"
    },
    {
        "link":"https://exchange.gmskripto.com/easytrade",
        "name":"Kolay Al/Sat"
    },
    {
        "link":"https://exchange.gmskripto.com/wallets",
        "name":"Cüzdan"
    }
]
